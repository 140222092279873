<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
      :pageSizes="pageSizes"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="formQuery"
          :items="searchItems"
          :loading="loading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <SlTableToolbar>
        <SlButton
          class="mr-8px"
          type="primary"
          boxShadow="primary"
          :loading="loading"
          :disabled="selections.length === 0"
          @click="openConfirmDialog"
        >接单</SlButton>
        <SlButton
          type="primary"
          boxShadow="primary"
          :loading="loading"
          :disabled="selections.length === 0"
          @click="openPriceAuditDialog"
        >成本复核</SlButton>
      </SlTableToolbar>
      <!-- 表格区域包含分页 -->
      <SlCardTable
        ref="cardTable"
        :data="tableData"
        :childColumns="childColumns"
        :columns="columns"
        :selections.sync="selections"
        selectable
        childName="purchaseOrderItemVoList"
      >
        <template #operation="{ row }">
          <el-button
            class="operation-btn"
            type="text"
            @click="tableOperation('showMaterials', row)"
          >查看生产资料</el-button>
          <el-button
            class="operation-btn"
            type="text"
            @click="tableOperation('printProcessSheet', row)"
          >打印工艺单</el-button>
          <el-button
            class="operation-btn"
            type="text"
            @click="tableOperation('downloadPattern', row)"
          >下载纸样</el-button>
        </template>
        <template #header="{row}">
          <el-tag
            class="mr-8px align-center"
            effect="plain"
            style="width: 54px;"
            :class="{'purple-tag': row.orderProcessType === 1, 'success-tag': row.orderProcessType === 2}"
          >{{row.orderProcessTypeName}}</el-tag>
          <el-tag v-if="row.fastOrderFlag" class="mr-8px float-right" type="danger">快返单</el-tag>
          <el-tag v-if="row.needRfid" class="mr-8px float-right">RFID</el-tag>
          <ul v-if="columns.length > 0" class="default-header-content">
            <li class="mr-24px" v-for="propItem in columns" :key="propItem.prop">
              <span class="header-prop-label mr-8px">{{propItem.label}}:</span>
              <span class="header-prop-value">{{row[propItem.prop]}}</span>
            </li>
          </ul>
        </template>
        <template #warehouseName="{row, item}">
          <p>{{row.warehouseName}}</p>
          <p v-if="item.orderProcessType === 1">
            <el-button
              type="text"
              @click="tableOperation('showSupplierDetail', row)"
            >{{item.processPlantSupplierName}}</el-button>
          </p>
        </template>
      </SlCardTable>
    </SlListView>

    <SlDialog
      title="确认接单"
      :visible.sync="confirmDialogVisible"
      :loading="loading"
      @submit="receiveOrder"
    >
      请确认商品信息、单价、订单数量后接单
      <div
        class="confirm-notice-text"
        v-if="needShowRFIDNotice"
      >选择的订单包含RFID标签，请接单后请导出或下单至RFID供应商系统；发货前需贴好产品标签</div>
    </SlDialog>

    <!-- 接单失败提示框 -->
    <SlDialog title="提示" :visible.sync="messageVisible">
      <el-alert
        class="mb-8px"
        :title="`成功${message.successOrderNum}个`"
        type="success"
        show-icon
        :closable="false"
      ></el-alert>
      <el-alert
        class="mb-8px"
        :title="`错误${message.failOrderNum}个`"
        type="error"
        show-icon
        :closable="false"
      ></el-alert>
      <el-tag
        class="mr-8px"
        v-for="(orderNo,index) in message.failOrderList"
        type="info"
        :key="'index_'+index"
      >{{orderNo}}</el-tag>
      <template v-slot:bottom>
        <el-button @click="closeMessageDialog">关闭</el-button>
      </template>
    </SlDialog>
    <!-- 打印大货工艺单 -->
    <PrintCraft ref="printCraft" />
    <SupplierDialog v-bind.sync="supplier" />
    <!-- 成本复核 -->
    <PriceReviewDialog ref="priceReview" @submit="priceReviewSubmit"></PriceReviewDialog>
    <!-- 接单、成本复核按钮操作提示弹窗 -->
    <SlDialog title="提示" :visible.sync="btnMessageVisible">
      {{btnMessageTip}}
      <template v-slot:bottom>
        <el-button @click="btnMessageVisible = false">关闭</el-button>
      </template>
    </SlDialog>
  </div>
</template>

<script>
import UserApi from '@api/user'
import OemGoodsAPI from '@api/oemGoods'
import { downloadBlobData } from '@/shared/util'
import PrintCraft from './pendingOrderList/PrintCraft'
import SupplierDialog from './pendingOrderList/SupplierDialog'
import PriceReviewDialog from './components/PriceReviewDialog'
import CommonUrl from '@api/url.js'
import axios from 'axios'

export default {
  name: 'PendingOrderList',
  components: {
    PrintCraft,
    SupplierDialog,
    PriceReviewDialog
  },
  data () {
    return {
      loading: false,
      confirmDialogVisible: false,
      messageVisible: false,
      btnMessageVisible: false,
      tableData: [],
      selections: [],
      extraQuery: {
        status: 0
      },
      formQuery: {},
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      pageSizes: [10, 20, 50],
      searchItems: [
        {
          type: 'batch-input',
          label: '生产订单号',
          name: 'purchaseOrderNumber'
        },
        {
          type: 'batch-input',
          label: 'SKU编码',
          name: 'sku'
        },
        {
          type: 'batch-input',
          label: 'UPC编码',
          name: 'upcs'
        },
        {
          type: 'input',
          label: '商品名称',
          name: 'commodityName'
        },
        {
          type: 'date',
          label: '下单时间',
          name: 'orderTimes',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        },
        {
          type: 'single-select',
          label: '快返单',
          name: 'fastOrderFlag',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'YES_NO' }
          }
        },
        {
          type: 'single-select',
          label: '订单类型',
          name: 'orderProcessType',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'ORDER_PROCESS_TYPE' }
          }
        }
      ],
      columns: [
        {
          prop: 'purchaseOrderNumber',
          label: '生产订单号'
        },
        {
          prop: 'orderTime',
          label: '下单时间'
        }
      ],
      childColumns: [
        {
          prop: 'skuCode',
          label: 'SKU编码'
        },
        {
          prop: 'upc',
          label: 'UPC编码'
        },
        {
          prop: 'skuImage',
          label: '商品图片',
          isImg: true,
          data: {
            imgSize: '8rem'
          }
        },
        {
          prop: 'name',
          label: '商品名称'
        },
        {
          prop: 'attributesName',
          label: '销售属性'
        },
        {
          prop: 'orderTypeName',
          label: '首/返单类型'
        },
        {
          prop: 'basePlateSkuCode',
          label: '底版SKU'
        },
        {
          prop: 'printedPattern',
          label: '花型号'
        },
        {
          prop: 'realPrice',
          label: '单价'
        },
        {
          prop: 'requireQuantity',
          label: '订单数量'
        },
        {
          prop: 'warehouseName',
          label: '到货仓库'
        },
        {
          prop: 'claimArrivalDate',
          label: '应交货时间'
        }, {
          prop: 'operation',
          label: '操作'
        }
      ],
      message: {
        failOrderNum: 0,
        successOrderNum: 0,
        failOrderList: []
      },
      supplier: {
        data: {},
        visible: false
      },
      btnMessageTip: ''
    }
  },
  computed: {
    needShowRFIDNotice () {
      const needRfidArr = this.selections.filter(item => item.needRfid)
      return (needRfidArr || []).length > 0
    }
  },
  mounted () { },
  methods: {
    // 若有修改，要同时修改 ProductionOrderDetail.vue
    tableOperation (type, row) {
      const { designCode, bomVersion, craftVersion, patternVersion, sizeVersion } = row
      const { href } = this.$router.resolve({
        path: 'production-materials',
        query: {
          designCode,
          bomVersion,
          craftVersion,
          patternVersion,
          sizeVersion
        }
      })
      switch (type) {
        case 'showMaterials':
          window.open(href, '_blank')
          break
        case 'printProcessSheet':
          if (!(designCode && bomVersion && craftVersion && sizeVersion)) {
            this.$message.error('存在值为空的版本号，无法打印工艺单！')
            return
          }
          OemGoodsAPI.designCraftPrint({
            designCode,
            bomVersion,
            craftVersion,
            sizeVersion
          }).then(res => {
            if (res.success) {
              const data = res.data || {}
              if (data.skc) {
                this.$refs.printCraft.show(res.data || {})
              } else {
                this.$message({
                  type: 'warning',
                  message: '工艺单无数据！'
                })
              }
            }
          })
          break
        case 'downloadPattern':
          OemGoodsAPI.designPattern({
            designCode,
            version: patternVersion
          }).then(res => {
            if (res.success) {
              const patternUrl = res.data && res.data.patternUrl
              if (patternUrl) {
                axios({
                  method: 'get',
                  url: patternUrl,
                  responseType: 'blob'
                }).then(inner => {
                  downloadBlobData(new Blob([inner.data]), `${res.data.spuCode}.prj`)
                })
              } else {
                this.$message({
                  type: 'warning',
                  message: '纸样下载的链接无效！'
                })
              }
            }
          })
          break
        case 'showSupplierDetail':
          const id = row.processPlantSupplierId
          UserApi.supplierShippingAddress(id).then(res => {
            if (res.success) {
              this.supplier = {
                data: res.data || {},
                visible: true
              }
            }
          })
          break
        default:
          break
      }
    },
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.loading = true
      OemGoodsAPI.getPurchaseOrderList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
          this.selections = []
        }
      }).finally(() => {
        this.loading = false
      })
    },

    receiveOrder () {
      const ids = this.selections.map(item => item.id)
      this.loading = true
      OemGoodsAPI.receiveOrder(ids).then(res => {
        let { success, data = {} } = res
        if (success) {
          let { failOrderList, result, successNum } = data
          if (result === true) {
            this.$message.success(`接单成功`)
            this.selections = []
            this.confirmDialogVisible = false
            this.gotoPage(this.page.pageSize)
            return
          }
          failOrderList = failOrderList || []
          this.message.failOrderNum = failOrderList.length
          this.message.successOrderNum = successNum
          this.message.failOrderList = failOrderList
          this.messageVisible = true
        }
      }).finally(() => {
        this.loading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      let { orderTimes = [], ...orther } = this.formQuery
      return {
        ...orther,
        ...this.extraQuery,
        pageIndex,
        pageSize,
        orderTimeStart: orderTimes && orderTimes[0] ? orderTimes[0] : '',
        orderTimeEnd: orderTimes && orderTimes[1] ? orderTimes[1] : ''
      }
    },
    openConfirmDialog () {
      if (this.btnHandleValidate()) {
        this.confirmDialogVisible = true
      }
    },
    closeMessageDialog () {
      this.confirmDialogVisible = false
      this.messageVisible = false
      this.selections = []
      this.gotoPage(this.page.pageSize)
    },
    openPriceAuditDialog () {
      if (this.btnHandleValidate()) {
        let skuRows = (this.selections || []).reduce((acc, cur) => {
          let baseObj = {
            purchaseOrderNumber: cur.purchaseOrderNumber
          }
          acc = acc.concat(cur.purchaseOrderItemVoList.map(item => {
            return {
              ...baseObj,
              id: item.id,
              skuCode: item.skuCode,
              skuImage: item.skuImage,
              name: item.name,
              attributesName: item.attributesName,
              originPrice: item.realPrice

            }
          }))
          return acc
        }, [])
        this.$refs.priceReview.openDialog(skuRows)
      }
    },
    btnHandleValidate () {
      let isWaitAuditPriceRecheckOrders = this.selections.filter(item => item.isWaitAuditPriceRecheck === true)
      if (isWaitAuditPriceRecheckOrders && isWaitAuditPriceRecheckOrders.length > 0) {
        this.btnMessageTip = `订单号：${isWaitAuditPriceRecheckOrders.map(item => item.purchaseOrderNumber).join()} 存在待处理成本复核,不能操作 !`
        this.btnMessageVisible = true
        return false
      }
      return true
    },
    priceReviewSubmit () {
      this.selections = []
      this.gotoPage(this.page.pageSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-btn {
  margin-left: 0 !important;
  margin-right: 0.8rem;
}
.purple-tag {
  color: purple;
  border-color: purple;
}
.success-tag {
  border-color: #c2e7b0;
  color: #67c23a;
}
.confirm-notice-text {
  margin-top: 15px;
  color: red;
}
</style>
